
// Variables
$color-pagination: #999999;
$color-primary: #212121;
$color-border: #e9e9e9;

/* board: Page Style */
.pagination-container{
    @at-root{
        [data-header-type="shop"] + .main .pagination-container{
            .pagination{
                 &--current { color: $color-primary; }
            }
        }
    }
    display: flex; justify-content: center; margin-top: 80px; margin-right: -4px; margin-left: -4px;
    .pagination{
        display: inline-flex; align-items: center; justify-content: center; position: relative; margin: 0 4px; width: 32px; height: 32px;
        text-align: center; font-size: 14px; color: $color-pagination; font-weight: 300;
        border: 1px solid transparent; border-radius: 0; cursor: pointer;
        svg { width: auto; height: 12px; fill: $color-pagination; }
        &--current { z-index: 2; color: $color-primary; font-weight: 700; }
        &--backward { border-color: $color-border; }
        &--prev { margin-right: 12px; border-color: $color-border; }
        &--next { margin-left: 12px; border-color: $color-border; }
        &--forward { border-color: $color-border; }
    }
}
.pagination-container--block{
    .pagination.current { color: #ffffff; background-color: $color-primary; border-color: $color-primary; }
}
@media (min-width:1025px){
    .pagination-container{
        @at-root{
            [data-header-type="shop"] + .main .pagination-container{
                .pagination:not(.current):hover { border-color: $color-primary; }
                .pagination:not(.current):hover svg { fill: $color-primary; }
            }
        }
        .pagination:not(.current):hover { border-color: $color-primary; }
        .pagination:not(.current):hover svg { fill: $color-primary; }
    }
}
@media(max-width:1024px){
	.pagination-container{
        margin-top: 25px;
        .pagination { font-size: 13px; }
    }
}
@media (max-width:576px){
    .pagination-container{
        margin-top: 25px;
        .pagination { 
            width:30px;
            height: 30px;
        }
    }
}
