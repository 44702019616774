// 폰트
// $font-en: 'Montserrat','Noto Sans KR','Apple SD Gothic Neo',Helvetica,sans-serif,Arial;
$font-en: 'Montserrat','Apple SD Gothic Neo',Helvetica,sans-serif,Arial;

// 컬러
$color-default: #222;
$color-disabled: #e9e9e9;
$color-primary: #222;
$color-primary-hover: #000;
$color-primary-background: #f7fcfe;
$color-primary-dark: #293039;
$color-secondary: #212121;
$color-secondary-hover: #000;
$color-mouse: #a6a9ad;
$color-mouse-hover: #888c92;
$color-red: #f55b5b;
$color-red-hover: #ff3d3d;
$color-pink: #ea68a2;
$color-pink-hover: #dc2e7c;
$color-yellow: #ffd610;
$color-orange: #ff9600;
$color-orange-hover: #e9a000;
$color-blue: #6a81ba;
$color-white: #ffffff;
$color-light: #e6e6e6;
$color-dark: #666666;
$color-dark-hover: #7a7a7a;

$background: #495666 url('/images/icon/background-pattern.jpg') repeat;
$border: #e6e6e6;
$border-dark: #999999;
$input-border-hover: #222;
$input-border-focus: #222;

$color-member-primary: #212121;

$shadow: rgba(36,36,36,0);

$color-mobile-gap: #ececec;

// 컨테이너 사이즈
$container: 1230px;
$container-lg: 1570px;

// 이미지 경로
$url: "/images";